<template>
  <div class="dropdown-action branding">
    <div
        class="dropdown-trigger"
        :class="{selected : currentBrandId || isOpen, hover: isOpen}"
        @mouseenter="isOpen=true"
        @mouseleave="isOpen=false"
        data-test="branding-dropdown-trigger"
    >
      <div class="icon">
        <branding-icon/>
      </div>

      <div class="text">
        <span>Brand kit</span>

        <IdmIcon v-if="!isOpen" name="arrow-down"/>
        <IdmIcon v-if="isOpen" name="arrow-up"/>
      </div>
    </div>

    <div
        v-if="brands && isOpen"
        class="dropdown-content"
        :class="{ empty : !brands.length}"
        @mouseenter="isOpen=true"
        @mouseleave="isOpen=false"
    >

      <div
          v-if="brands.length"
          class="brand-sort"
          @click="sortAsc = !sortAsc"
          data-test="branding-sort-brand-button"
      >
        <div class="txt1">Last modified</div>
        <div class="txt2" v-if="sortAsc">A-Z</div>
        <div class="txt2" v-if="!sortAsc">Z-A</div>
      </div>
      <div
          v-if="brands.length"
          v-for="(brand) in sortedBrands"
          :key="brand._id"
          class="row"
          :data-test="`branding-brand-${brand._id}-item`"
          >
        <label class="row-label">
          <IdmRadio
              class="radio-btn"
              :value="brand._id"
              :checked="currentBrandId === brand._id"
              @check-radio="brandChanged"
              :data-test="`branding-change-brand-${brand._id}-radio`"
          />
          <span class="brand-name" @click="brandClickEvent(brand._id)" :data-test="`branding-apply-brand-${brand._id}-button`">{{ brand.name }}</span>
        </label>
      </div>

      <div
          v-if="brands.length"
          class="row">
        <label class="row-label">
          <IdmRadio
              class="radio-btn"
              :value="null"
              :checked="currentBrandId === null"
              @check-radio="brandChanged"
              data-test="branding-reset-brand"
          />
          <span class="brand-name">No Brand Applied</span>
        </label>
      </div>

      <div
          v-if="!brands.length"
          class="empty-brands"
      >
        <span class="empty-title">Adding a brand kit</span>
        <span class="empty-text">If you’d like to add a new brand kit, simply contact our support team at:</span>
        <a class="empty-link" href="mailto:support@idomoo.com" data-test="branding-email-link">support@idomoo.com</a>
        <IdmButton
            :btnType="'secondary'"
            :text="'Close'"
            @click="closeBrandsInfo"
            data-test="branding-close-button"
        ></IdmButton>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";
import {MediaType} from '@/helper/consts';
import getMediaType from "@/helper/getMediaType";
import brandingIcon from "./brandingIcon.vue"
import sceneCacheStorage from '@/helper/cache';

export default {
  name: 'branding',

  components: {
    'branding-icon': brandingIcon
  },

  data() {
    return {
      sortAsc: true,
      isOpen: false,
    }
  },

  computed: {
    scene() {
      return this.$store.getters['builder/scene'];
    },
    brands() {
      return this.$store.getters['builder/brands'];
    },
    sortedBrands() {
      const brands = this.brands
      return _.orderBy(
          brands,
          [brand => brand.name.toLowerCase(), 'lastModifiedDate'],
          [this.sortAsc ? 'asc' : 'desc', 'asc']
      );
    },
    currentBrandId() {
      return this.$store.getters['builder/currentBrandId'];
    },
  },

  methods: {
    ...mapActions('builder', [
      'applyBrandStyles',
    ]),
    closeBrandsInfo() {
      this.isOpen = false;
    },
    applyBrand(brandId) {
      this.closeBrandsInfo();
      this.applyBrandStyles(brandId);
      this.$emit('preview');
    },
    brandClickEvent(brandId) {
      if (this.currentBrandId === brandId) {
        this.applyBrand(brandId);
      }
    },
    brandChanged(option) {
      if (!option.status) {
        return;
      }

      this.applyBrand(option.value);
    }
  },
}
</script>

<style lang="scss" scoped>
.branding {
  display: flex;
  justify-content: center;
  margin-left: auto;
  height: 32px;

  .dropdown-trigger {
    display: flex;
    align-items: center;
    border-radius: 8px;

    &.hover {
      background: #fff;
    }

    &.selected {
      .icon {
        background: linear-gradient(90deg, #3DBCFF 0%, #1A6DFF 100%);

        svg {
          fill: #fff;
        }
      }
    }
  }

  .dropdown-content {
    right: unset;
    max-width: 304px;
    min-width: 304px;
    background-color: #FFFFFF;


    &.empty {
      max-width: 415px;
      min-width: 415px;
    }
  }

  .brand-sort {
    height: 32px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 8px;

    &:hover {
      cursor: pointer;
    }

    .txt1 {
      user-select: none;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #07354A;
    }

    .txt2 {
      user-select: none;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #ACBCC3;
    }
  }

  .icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E6F6FE;
    border-radius: 8px;

    svg {
      fill: #07354A;
    }
  }

  .text {
    display: flex;
    align-items: center;
    padding: 0 8px;

    /* Dark 500 */
    color: #07354A;
    font-weight: 400;

    svg {
      width: 16px;
      height: 16px;
      margin-left: 8px;
    }
  }

  .row {
    height: 48px;
    display: flex;
    align-items: center;
    overflow: hidden;

    &:not(:first-child) {
      border-top: 1px solid #EEEEEE;
    }

    &:last-child .row-label:hover {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .row-label {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 10px 10px 10px 12px;

      &:hover {
        cursor: pointer;
        background-color: #def3fe;
      }

      .icon {
        background-color: transparent;
        width: 18px;
        height: 16px;
        margin-left: 8px;
      }

      .brand-name {
        font-weight: 400;
        margin-left: 8px;
        font-style: normal;
        font-size: 16px;
        line-height: 21px;

        user-select: none;
        flex-grow: 1;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }

  .empty-brands {
    display: flex;
    flex-direction: column;
    padding: 24px 16px;

    .empty-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #07354A;
    }

    .empty-text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #07354A;
      margin-top: 16px;
    }

    .empty-link {
      margin-top: 11px;
      text-decoration: underline;
    }

    .idm-btn {
      margin-top: 32px;
    }
  }
}
</style>