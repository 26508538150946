<template>
  <div class="editor-text">
    <div class="editor-text-title">
      <span>Edit text</span>
      <IdmButton
        :btnType="'small outline'"
        :disabled="!node.edited"
        :text="'Clear changes'"
        @click="clearTextChanges"
        data-test="text-editor-clear-changes-button"
      ></IdmButton>
    </div>
    <IdmTextEditor
      :placeholder="textObj"
      :pixelIdSection="'pixelArea'"
      :hidePreview="true"
      @get-popup-error="getPopupError"
      @generate-data="generateData"
      @get-uploaded-fonts="getUploadedFonts"
      :uploadedFonts="uploadedFonts"
      :googleFonts="googleFonts"
      :btnText="'Preview'"
      :key="count"
      data-test="text-editor-component"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
import { TextFields } from '@/helper/consts';
import { fontsCache } from '@/helper/fontsCache';
import googleFonts from '@/json/googleFonts.json';

export default {
  name: 'textEditor',

  props: ['node', 'sceneId'],

  data() {
    return {
      textObj: {},
      uploadedFonts: [],
      googleFonts: [],
      count: 0,
      skipFirstGenerate: false,
      previewTimeoutID: null,
      previewTimeout: 2000,
    };
  },

  watch: {
    node() {
      this.resetTextEditor();
    },
    'node.branding'(newKey, oldKey) {
      this.resetTextEditor();
    },
    'node.edited'(newKey, oldKey) {
      if (!newKey) {
        this.resetTextEditor();
      }
    },
  },

  methods: {
    ...mapActions('builder', [
      'updateNodeValue',
      'undoNodeChanges',
      'hideNode',
      'updateNodeTags',
    ]),
    resetTextEditor() {
      this.setTextObjProperties();
      this.count++;
    },
    setPreviewTimeout(generatedData) {
      this.clearTimeOut();
      this.previewTimeoutID = setTimeout(() => {
        this.updateNodeValue({ node: this.node, data: generatedData });
        this.$emit('preview');
      }, this.previewTimeout);
    },
    clearTimeOut() {
      if (this.previewTimeoutID != null) {
        clearTimeout(this.previewTimeoutID);
        this.previewTimeoutID = null;
      }
    },
    clearTextChanges() {
      const isHidden = this.node.is_hidden;
      const tags = this.node.tags;

      this.undoNodeChanges();

      if (isHidden) {
        console.info('show node');
        this.hideNode(this.node);
      }

      if (!_.isEqual(tags.sort(), this.node.tags.sort())) {
        this.updateNodeTags({node: this.node, tags: tags,});
      }

      this.$emit('preview');
      this.resetTextEditor();
    },
    clearAllChanges() {
      this.undoNodeChanges();
      this.$emit('preview');
      this.resetTextEditor();
    },
    setTextObjProperties() {
      this.clearTimeOut();
      this.textObj = {};

      _.set(this.textObj, 'key', this.node.key);

      _.forEach(this.node, (val, key) => {
        if (_.indexOf(TextFields, key) >= 0) {
          _.set(this.textObj, key, val);
        }
      });

      this.skipFirstGenerate = true;
    },
    getPopupError(data) {
      this.$emit('showErrorPopup', data);
    },
    generateData(data) {
      console.log('data');
      console.log(data);
      if (this.skipFirstGenerate) {
        this.skipFirstGenerate = false;
        return;
      }

      if (data.debounce) {
        this.setPreviewTimeout(data);
      } else {
        this.updateNodeValue({ node: this.node, data });
        this.$emit('preview');
      }
    },
    getUploadedFonts(data) {
      fontsCache.updateSceneFonts(this.sceneId, data);
    },
  },

  created() {
    this.googleFonts = googleFonts;

    this.setTextObjProperties();
    this.uploadedFonts = fontsCache.getSceneFonts(this.sceneId);
  },
};
</script>

<style lang="scss">
.idm-btn.primary.generate-data-btn {
  margin-top: 12px;
  margin-left: auto;
}

.editor-text {
  display: flex;
  flex-direction: column;
  margin-top: 14px;

  .editor-text-title {
    margin-bottom: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--dark-500);

    span {
      font-weight: 700;
    }
  }
}
</style>
