<template>
  <div class="editor-wrap" v-if="activeComponent && isSupportedEditor">
    <component
      :is="activeComponent"
      :node="selectedNode"
      :scene-id="sceneId"
      @preview="$emit('preview')"
      @show-error-popup="$emit('showErrorPopup', $event)"
      :key="selectedNode.placeholder?.id || selectedNode.id"
    />

    <div class="tags-wrap">
      <span>Tags (Separate by ;) </span>
      <IdmInput
        v-if="showTags"
        :placeholder="'Intro;Outro'"
        :tags="true"
        :tagsList="selectedNode.tags"
        @inputTags="inputTags"
        data-test="editors-tags-input"
      />
    </div>
  </div>
</template>

<script>
import textEditor from './text';
import mediaEditor from './media';
import audioEditor from './audio';
import { mapActions } from 'vuex';
import sceneCacheStorage from '@/helper/cache';
export default {
  name: 'editors',

  props: ['selectedNode', 'sceneId'],

  components: {
    textEditor,
    mediaEditor,
    audioEditor,
  },

  data() {
    return {
      showTags: true,
      nodeChanged: false,
      inputChanged: false,
      supportedTypes: ['textEditor', 'mediaEditor', 'audioEditor'],
    };
  },

  watch: {
    'selectedNode.key'(newKey, oldKey) {
      this.nodeChanged = true;
    },
    'selectedNode.tags'(newTags, oldTags) {
      const tagsEqual = _.isEqual(newTags.sort(), oldTags.sort());

      if (this.inputChanged) {
        this.inputChanged = false;
        return;
      }

      if (this.nodeChanged || !tagsEqual) {
        this.rerenderTags();
      }
    },
  },

  computed: {
    scene() {
      return this.$store.getters['builder/scene'];
    },
    activeComponent() {
      const media =
        this.selectedNode.media_type === 'video' ||
        this.selectedNode.media_type === 'color' ||
        this.selectedNode.media_type === 'image';
      return this.selectedNode
        ? media
          ? 'mediaEditor'
          : this.selectedNode.media_type + 'Editor'
        : null;
    },
    isSupportedEditor() {
      return this.supportedTypes.indexOf(this.activeComponent) >= 0;
    },
  },

  methods: {
    ...mapActions('builder', ['updateNodeTags']),

    rerenderTags() {
      this.showTags = false;
      this.$nextTick(() => {
        this.nodeChanged = false;
        this.showTags = true;
      });
    },
    inputTags(tagsData) {
      const tags = _.reject(tagsData, (tag) => {
        return tag === '';
      });
      this.inputChanged = true;
      this.updateNodeTags({
        node: this.selectedNode,
        tags: tags,
      });
      sceneCacheStorage.updateSceneCache(this.scene);
    },
  },
};
</script>

<style lang="scss" scoped>
.tags-wrap {
  color: var(--dark-500);
  margin-top: 24px;
  padding-top: 21px;
  border-top: 1px solid #dbdbdb;

  .idm-input-container {
    margin-top: 11px;
  }
}
</style>
